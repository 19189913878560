<template>
  <div class="notfound" v-if="!isProcessing">
    <div class="notfound__item">
      <h1 class="notfound__item__title">404</h1>
      <p class="notfound__item__explain">
        ページが存在しません<br>
        アクセスしたページを確認ください
      </p>
      <v-btn class="notfound__item__btn" outlined @click="$router.push({ name: 'home' })">ホームへ戻る</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notfound',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.notfound {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
  text-align: center;
  &__item {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      font-size: 3.2rem;
      line-height: 3.7rem;
      color: $red_color;
    }
    &__explain {
      margin: 20px 0 60px 0;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $gray_darken_color;
    }
    &__btn {
      width: 120px;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: $orange_color;
      border-radius: 10px;
      box-shadow: none;
      &.v-btn:not(.v-btn--round) {
        height: 40px;
      }
      &.theme--light.v-btn:not(.v-btn--flat) {
        background-color: $orange_color;
      }
    }
  }
}
</style>
